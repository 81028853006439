@use '../../../base_css/abstracts/abstracts' as *;
@use './cta_info_card_commons';

.cta-info-card {
  width: 100%;

  &__checkboxes-list {
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;

    .btn-primary,
    .secondary-btn {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &--blue {
    height: 100%;
    padding: 24px;
  }
}
