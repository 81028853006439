@use './home_megabanner_slider_commons';
@use './cta_info_card/cta_info_card_mobile';

.home-megabanner-slider {
  grid-template-areas:
    'fastbooking'
    'lastsearches'
    'slider';
  margin-bottom: 40px;

  &__header-image {
    width: 100%;
    height: 136px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &__slider {
    margin-top: -40px;
    border-bottom: 1px solid var(--c_cloud);

    &.margin-extra {
      margin-top: 20px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
  }

  &__media {
    position: relative;
    height: 300px;
  }

  &__info {
    position: relative;
    flex-grow: 1;

    &.wrapper {
      padding: 0;
    }
  }

  &__pagination {
    bottom: 24px;
  }

  &__controller {
    display: none;
  }

  &__fastbooking {
    .ibh-fastbooking {
      // margin-top: -47px;
      margin-bottom: 25px;
    }
  }

  .nav-btn-prev,
  .nav-btn-next {
    display: none
  }
}
